import logger from './logger';

const APP_CODE = 'm3tdgo';
const TIMEOUT_M_TOKEN_PREFIX = '_TdjH8dr_';

const services = {
  df: null,
  checker: null,
};

function getListener(buttonId, callback) {
  try {
    if (!services.checker) {
      init();
    }

    return services.checker.listen(buttonId, (mToken) => {
      if (mToken?.startsWith(TIMEOUT_M_TOKEN_PREFIX)) {
        logger.error('ShoplineCaptcha get timeout m_token', { mToken });
      }
      callback(mToken);
    });
  } catch (e) {
    logger.error('ShoplineCaptcha getListener error', {
      checker: window.DeviceFingerprint,
      extraPayload: [buttonId, callback],
      e,
    });
  }
}

function getTokenAsync() {
  try {
    if (!services.df) {
      init();
    }
    return services.df.getTokenAsync();
  } catch (e) {
    logger.error('ShoplineCaptcha getTokenAsync error', {
      df: window.DeviceFingerprint,
      e,
    });
  }
}

function init() {
  try {
    if (window.DeviceFingerprint && window.mainConfig) {
      const df = window.DeviceFingerprint?.({
        env: window.mainConfig.shoplineCaptchaEnv,
        appCode: APP_CODE,
      });

      const checker = window.DeviceFingerprint?.mmc({
        env: window.mainConfig.shoplineCaptchaEnv,
        publicKey: window.mainConfig.shoplineCaptchaPublicKey,
        timeout: 1000,
      });
      services.df = df;
      services.checker = checker;
    }
  } catch (e) {
    logger.error('ShoplineCaptcha initialized error', e);
  }
}

const shoplineCaptcha = {
  getListener,
  getTokenAsync,
};

init();

export default shoplineCaptcha;
